
























































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'popo-info': () => import('@/modules/popo/components/details/popo-info.vue'),
    'decorate-more': () => import('@/modules/reward/components/popo/decorate-more.vue'),
    'attach-nft-section': () => import('@/modules/popo/components/details/attach-nft-section.vue'),
    'decorated-popo-empty': () => import('@/modules/reward/components/decorated-popo-empty.vue'),
  },
})
export default class extends Vue {
  decorators = ['hair1', 'eyes1', 'hat1', 'dress1']
  popoStore = popoStore

  get popoItemPerRow() {
    return this.$vuetify.breakpoint.smOnly ? 2 : this.$vuetify.breakpoint.mdOnly ? 4 : 6
  }
}
