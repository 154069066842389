var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column gap-6 padding-horizontal-mobile full-height"},[(!_vm.popoStore.loadedDecoratedPopos)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):(_vm.popoStore.activeDecoratedPopoNftInfo)?_c('div',[_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"flex-fill",attrs:{"cols":"12","md":"8"}},[_c('popo-info',{attrs:{"decoratedPopoInfo":_vm.popoStore.activeDecoratedPopoNftInfo}})],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-col',{attrs:{"cols":"0","md":"4"}},[(
            _vm.popoStore.activeDecoratedPopoNftInfo &&
            _vm.popoStore.activeDecoratedPopoNftInfo.wearableGalleries &&
            _vm.$vuetify.breakpoint.lgAndUp
          )?_c('attach-nft-section',{attrs:{"nfts":_vm.popoStore.activeDecoratedPopoNftInfo.wearableGalleries,"showTitle":false,"decoratedPopoInfo":_vm.popoStore.activeDecoratedPopoNftInfo}}):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(
      _vm.popoStore.activeDecoratedPopoNftInfo &&
      _vm.popoStore.activeDecoratedPopoNftInfo.wearableGalleries &&
      !_vm.$vuetify.breakpoint.lgAndUp
    )?_c('attach-nft-section',{attrs:{"nfts":_vm.popoStore.activeDecoratedPopoNftInfo.wearableGalleries,"decoratedPopoInfo":_vm.popoStore.activeDecoratedPopoNftInfo}}):_vm._e(),(_vm.popoStore.havePendingNFTs)?_c('div',[_c('v-btn',{staticClass:"warning--text font-italic text-body4",attrs:{"text":""},on:{"click":function($event){return _vm.popoStore.changeOpenRemindClaimPopoDialog(true)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning","small":""}},[_vm._v("mdi-alert")]),_vm._v(" You have not claimed POPO to your wallet! ")],1)],1):_vm._e(),(_vm.popoStore.builtDecoratedPopos && _vm.popoStore.builtDecoratedPopos.length)?_c('decorate-more'):(_vm.popoStore.loadedDecoratedPopos)?_c('decorated-popo-empty'):_vm._e(),(_vm.popoStore.builtDecoratedPopos && _vm.popoStore.builtDecoratedPopos.length)?_c('div',{staticClass:"text-title1"},[_vm._v(" Current POPO ")]):_vm._e(),(_vm.popoStore.builtDecoratedPopos && _vm.popoStore.builtDecoratedPopos.length)?_c('popo-list',{attrs:{"itemPerRow":_vm.popoItemPerRow,"nfts":_vm.popoStore.builtDecoratedPopos,"showActiveNft":true,"showAllItems":true,"showCode":true,"showAttributeLevel":true},on:{"click":function($event){return _vm.$router.push(("/nft/" + $event))}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }